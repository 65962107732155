export interface Claims {
    hasPushAsinAccess: Boolean;
    internalGroup: string;
    isInternal: Boolean;
    pushAsinGroup: string;
    status: Boolean;
    userName: string;
    waitTimes: Record<string, string>;
}


export const UPDATE_CLAIMS: string = "@@CLAIMS/UPDATE_CLAIMS";

interface UPDATE_CLAIMS_ACTION {
    type: typeof UPDATE_CLAIMS;
    payload: Claims;
}

export type ClaimsActionTypes = UPDATE_CLAIMS_ACTION;