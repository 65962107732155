import {API} from "aws-amplify";
import {API_NAME} from "src/constants/app";

/**
 * ping apis just to make sure backand lambda is responding
 */
export const getEcho = async (inputString) => {
    try {
        const path = '/echo';
        const body = {
            body: {"string": inputString || "Test Call to validate apis."},
        };
        return await API.post(API_NAME, path, body);
    } catch (error) {
        console.error(`Error while calling Echo API : ${error}`)
    }
}

export const submitFeedback = async (credentials, title, feedback) => {
    try {
        const path = '/submitFeedback';
        const body = {
            body: {
                "token": credentials,
                title,
                feedback
            }
        };
        return await API.post(API_NAME, path, body)
    } catch (error) {
        console.log(`Error while calling submit feedback API : ${error}`)
        return {"errorMessage": error.message}
    }
}


export const fetchUser = async (credentials) => {
    try {
        const path = '/getUser';
        const body = {
            body: {
                "token": credentials
            }
        };
        return await API.post(API_NAME, path, body)
    } catch (error) {
        console.log(`Error while calling fetchUser API : ${error}`)
        if (error.response && error.response.data && error.response.data.message)
            return {"errorMessage": error.response.data.message}
        return {"errorMessage": error.message}
    }
}
