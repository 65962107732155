import contrib from "lodash-contrib";
import {format} from "date-fns";
import {has, toInteger, values} from "lodash";
import {PAGE_LOCATIONS, PUSH_ASIN_REQUESTING_USER_PARAM, PUSH_ASIN_RUN_ID_PARAM} from "src/constants/app";


export const renameKeys = {
    "userId": "User",
    "runId": "Run Id",
    "asins": "Asins",
    "asinIdentityMap": "Asin Map",
    "profileId": "Profile Id",
    "jobId": "Job Id",
    "jobRunId": "Job Run Id",
    "actor": "Actor",
    "currentStatus": "Status",
    "cradleJobStatus": "Cradle Status",
    "reason": "Reason",
    "forced": "Is Forced",
    "createdAt": "Created On",
    "updatedAt": "Updated On",
    "deleted": "Is Deleted",
    "action": "Action",
    "_happyCase": "Happy Case",
    "_breachedSla": "Breached SLA",
    "_showInfo": "Show Info"
}
export const hiddenItems = [renameKeys.userId, renameKeys.asinIdentityMap, renameKeys.profileId,
    renameKeys.jobId, renameKeys.jobRunId, renameKeys.reason, renameKeys.actor, renameKeys.forced, renameKeys.cradleJobStatus]
export const extraItems = [renameKeys.userId, renameKeys._happyCase, renameKeys._breachedSla, renameKeys._showInfo]
export const toOmit = [renameKeys.deleted]
export const dateColumns = [renameKeys.createdAt, renameKeys.updatedAt]
export const MAX_ALLOWED_LENGTH = 15;
export const MAX_LENGTH_EXCLUDED_FIELDS = [renameKeys.createdAt, renameKeys.updatedAt, renameKeys.currentStatus, renameKeys.cradleJobStatus]

export const formatUserJobInfos = (JobInfos, stageConfig, claimsData) => {
    if (!JobInfos) return []
    return JobInfos.map(r => {
        r = contrib.renameKeys(r, renameKeys)
        values(renameKeys).forEach(v => {
            if (!has(r, v)) r[v] = ""
        })
        //keep only one status column
        if (r[renameKeys.cradleJobStatus] === 'SUCCESS' && r[renameKeys.currentStatus] === 'CREATE_JOB_RUN') {
            r[renameKeys.currentStatus] = 'SHERLOCK_PENDING'
        } else if (r[renameKeys.currentStatus] === 'CREATE_JOB_RUN') {
            r[renameKeys.currentStatus] = r[renameKeys.cradleJobStatus]
        }
        const time_passed = toInteger(Date.now()) - toInteger(r[renameKeys.createdAt])
        r[renameKeys._happyCase] = r[renameKeys.currentStatus] === 'SUCCEEDED'
        r[renameKeys._breachedSla] = r[renameKeys.currentStatus] !== 'SUCCEEDED' && time_passed > claimsData?.waitTimes?.maxCompleteWaitTime
        r[renameKeys._showInfo] = r[renameKeys.currentStatus] !== 'SUCCEEDED'

        dateColumns.map(dc => {
            const timestamp = r[dc]
            const converted = format(new Date(toInteger(timestamp)), stageConfig.DATE.format)
            r[dc] = converted === "01/01/1970" ? timestamp : converted
            return true;
        })
        toOmit.map(t => delete r[t])
        return r
    })

}

export const MAX_JOBS_PAGE_COUNT = 10;

export const GET_RUN_ID_URL = (runId, userName) => {
    return window.location.origin + "/#" + PAGE_LOCATIONS.PUSH_ASIN + `?${PUSH_ASIN_RUN_ID_PARAM}=${runId.trim()}&${PUSH_ASIN_REQUESTING_USER_PARAM}=${userName?.trim()}`
}
export const MILLISECONDS_TO_HOURS = (ms) => ms / (1000 * 60 * 60);