import {AppState, AppActionTypes} from "./types";
import {Reducer} from "redux";

const initialState: AppState = {
    isInitializing: true,
    errored: false
};

export const AppReducer: Reducer<AppState> = (
    state: AppState = initialState,
    action
) => {
    switch ((action as AppActionTypes).type) {
        case "@@APP/UPDATE_APP_STATE":
            return {...state, ...action.payload};
        default:
            return state;
    }
};