import {createStore, applyMiddleware} from "redux";
import {rootReducer} from "src/reducers/index";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";

export default function configureStore() {
    const middlewares = [thunk];
    const middlewareEnhancer = applyMiddleware(...middlewares);
    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);
    return createStore(rootReducer, composedEnhancers);
}