import React, {useContext, useEffect, useCallback} from "react"
import useState from 'react-usestateref'

import {LottieAnimation} from "src/components/common/LottieAnimation";
import {useSelector} from "react-redux";
import Column from "@amzn/meridian/column";
import {useHistory, useLocation,} from "react-router-dom";
import {StyleSheet, css} from 'aphrodite';
import {fadeIn} from 'react-animations';

import {refreshMidway} from "src/authentication/midwayTokenRetriever";
import loading1 from "src/animations/loading1.json";
import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Loader from "@amzn/meridian/loader";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import {
    APP_NAME,
    DELAY,
    PAGE_LOCATIONS,
    PUSH_ASIN_FORCE_CREATE_URL_PARAM,
    PUSH_ASIN_REQUESTING_USER_PARAM,
    PUSH_ASIN_RUN_ID_PARAM,
    PUSH_ASIN_URL_PARAM
} from "src/constants/app";
import {PushInvokedView} from "src/components/layouts/push/PushInvoked";
import Card from "@amzn/meridian/card";
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import Link from "@amzn/meridian/link";
import {UserJobs} from "src/components/layouts/push/UserJobs";
import {getCurrentStageConfig} from "src/constants/stage";
import {formatUserJobInfos, MILLISECONDS_TO_HOURS} from "src/components/layouts/push/UserJobsConstants";
import {getCradleJobs, invokedCradleAction} from "src/apis/pushAsin";
import Textarea from "@amzn/meridian/textarea";
import {fadeInStyles} from "src/util/customModalStyle";
import locked from "src/animations/locked.json";
import locked1 from "src/animations/locked1.json";
import {Feedback} from "src/components/layouts/Feedback";
import {v4 as uuidv4} from "uuid";
import {ThemeContext} from "src/context/ThemeContext";
import {JobRunInfo} from "src/components/layouts/push/JobRunInfo";


const SINGLE_ASIN_MIN_LENGTH = 3;
export const PushHomeView = (props) => {
    const stageConfig = getCurrentStageConfig();

    const userNameSelector = (state) => state.user;
    const userData = useSelector(userNameSelector);
    const claimsSelector = (state) => state.claims;
    const claimsData = useSelector(claimsSelector);
    const search = useLocation().search;
    const urlParams = new URLSearchParams(search);
    const history = useHistory();


    const [isMainLoading, setIsMainLoading] = useState(false);
    const [isLinearLoading, setIsLinearLoading, linearLoadingRef] = useState(false);
    const [asins, setAsins, asinsRef] = useState("")
    const [asinInputError, setAsinInputError] = useState("")
    const [isAsinInputDisabled, setIsAsinInputDisabled, asinInputDisabledRef] = useState(false)
    const [formattedAsin, setFormattedAsin, formattedAsinRef] = useState("")
    const [normalAsins, setNormalAsins, normalAsinsRef] = useState("")
    const [forceCreateJob, setForceCreateJob, forceCreateJobRef] = useState(false)

    const [userJobs, setUserJobs, userJobsRef] = useState([])
    const [currentPage, setCurrentPage, currentPageRef] = useState(1)
    const [runIdUrlParam, setRunIdUrlParam, runIdUrlParamRef] = useState("")
    const [runResponse, setRunResponse, runResponseRef] = useState(null)

    const themeContext = useContext(ThemeContext);
    const [openFeedBackView, setOpenFeedbackView] = useState(false);

    const [infoToasts, setInfoToasts] = useState([])
    const onCloseInfoToast = useCallback(id => setInfoToasts(infoToasts.filter(t => t.id !== id)), [infoToasts])
    let dummyId = 0
    const onOpenInfoToast = useCallback((message) => setInfoToasts(infoToasts.concat({
        id: `${++dummyId}`, message, timeout: 3000
    })), [infoToasts])


    useEffect(async () => {
        setIsMainLoading(true)
        if (claimsData?.hasPushAsinAccess) {
            if (urlParams.get(PUSH_ASIN_RUN_ID_PARAM) != null && urlParams.get(PUSH_ASIN_RUN_ID_PARAM)) {
                const credentials = await refreshMidway();
                const response = await invokedCradleAction(credentials.token, null,
                    "GET_FINAL_RUN_STATUS", null, null, urlParams.get(PUSH_ASIN_RUN_ID_PARAM).trim(),
                    (urlParams.get(PUSH_ASIN_REQUESTING_USER_PARAM) != null && urlParams.get(PUSH_ASIN_REQUESTING_USER_PARAM)) ? urlParams.get(PUSH_ASIN_REQUESTING_USER_PARAM) : null
                );
                if (response && response?.errorMessage) {
                    onOpenInfoToast(response.errorMessage)
                }
                setRunResponse(response)
                setRunIdUrlParam(urlParams.get(PUSH_ASIN_RUN_ID_PARAM).trim())
                setIsMainLoading(false)
            } else {
                const credentials = await refreshMidway();
                const jobs = await getCradleJobs(credentials.token);
                if (jobs?.errorMessage) {
                    onOpenInfoToast(jobs.errorMessage)
                }
                setUserJobs(jobs)
                setIsMainLoading(false)

                if (urlParams.get(PUSH_ASIN_FORCE_CREATE_URL_PARAM) != null && urlParams.get(PUSH_ASIN_FORCE_CREATE_URL_PARAM)) {
                    setForceCreateJob(true)
                }
                //received inputs form url
                if (urlParams.get(PUSH_ASIN_URL_PARAM) != null) {
                    setAsins(urlParams.get(PUSH_ASIN_URL_PARAM))
                    pushAsinOnClick()
                }
            }
        } else {
            setIsMainLoading(false)
        }
    }, [])

    useEffect(async () => {
        console.log("run id changed ", runIdUrlParamRef?.current)
    }, [runIdUrlParam])

    /**
     * push asin main handler
     * @returns {Promise<void>}
     */
    const pushAsinOnClick = async () => {
        try {
            setIsLinearLoading(true)
            setIsAsinInputDisabled(true)
            setFormattedAsin(null)
            const asinArray = asinsRef.current ? asinsRef.current.split(",").map(a => a.replace(/[^a-zA-Z0-9]/g, "").trim()) : []
            if (asinArray.some(a => a.length < SINGLE_ASIN_MIN_LENGTH)) {
                throw new Error("one or more asin entered is invalid.")
            } else if (asinArray.length <= 0) {
                throw new Error("please enter at least one asin.")
            } else {
                history.push({
                    pathname: PAGE_LOCATIONS.PUSH_ASIN, search: `?${new URLSearchParams({
                        [PUSH_ASIN_URL_PARAM]: asinArray.join(","),
                        [PUSH_ASIN_FORCE_CREATE_URL_PARAM]: forceCreateJobRef?.current
                    })}`
                })
                await DELAY(2000)
                setNormalAsins(asinArray.join(","))
                setFormattedAsin(`'${asinArray.join("','")}'`)
            }
        } catch (error) {
            onOpenInfoToast(error.message)
            setAsinInputError(error.message)
            setIsLinearLoading(false)
            setIsAsinInputDisabled(false)
        } finally {
            // setIsLinearLoading(false)
            // setIsAsinInputDisabled(false)
        }
    }

    return (<>
        {/*info toaster*/}
        <Toaster toasts={infoToasts} onCloseToast={onCloseInfoToast}
                 alignmentHorizontal="center">
            {toast => <Alert toast={true}
                             type="error"
                             size="large"
            >{toast.message}</Alert>}
        </Toaster>
        {isLinearLoading && <Row width="100%" widths={["100%"]} alignmentVertical="bottom" alignmentHorizontal="left">
            <Loader type="linear" size="small"/>
        </Row>}
        {isMainLoading && <div style={{justifyContent: "center", alignItems: "center", display: "flex"}}>
            <div>
                <LottieAnimation animationData={loading1}
                                 text={""}
                                 height={100}
                                 width={100}/>
            </div>
        </div>}
        {!isMainLoading &&
            <Column heights="fit" spacing="none" spacingInset="400" wrap="down" alignmentHorizontal="start"
                    alignmentVertical="top">
                {/*header*/}
                <div style={{
                    width: '100%', marginTop: "40px", justifyContent: "start", alignItems: "start", display: "flex",
                }}>
                    <Row width="100%" widths={["grid-12"]} alignmentVertical="bottom" alignmentHorizontal="left"
                    >
                        <div style={{borderRadius: "10px", border: "1px solid #ccc", padding: "10px"}}>
                            {/*<Text type=""><strong>Key points to note:</strong></Text>*/}
                            <Text type="">• For permissions you need to be a member of <Link
                                href={"https://permissions.amazon.com/a/team/" + claimsData?.pushAsinGroup}
                                target="_blank"
                                rel="noopener noreferrer"
                                type="primary">{claimsData?.pushAsinGroup + " "}
                                team</Link>. You can use the Help Menu above to raise a permission request. </Text>
                            <Text type="">• After pushing an asin please <strong>wait for at least 30 minutes for
                                the
                                cradle
                                job to
                                finish.</strong>
                                The status will be visible on the main view below.</Text>
                            <Text type="">• After the cradle job finishes, it might takes around 1-2 hours for the
                                step
                                function to trigger and complete. <strong>If the status is not successful
                                    after {MILLISECONDS_TO_HOURS(claimsData?.waitTimes?.maxCompleteWaitTime)} hrs you
                                    can
                                    reach
                                    out to the team via the help menu above. </strong></Text>
                        </div>
                    </Row>
                </div>
                {claimsData?.hasPushAsinAccess ? <>
                    {runIdUrlParamRef?.current ? <Row width="100%" widths={["100%"]} alignmentVertical="bottom"
                                                      alignmentHorizontal="center"
                                                      spacingInset="400">
                        <div className={css(fadeInStyles.fadeInAnimation)}>
                            <JobRunInfo
                                runResponse={runResponseRef?.current.errorMessage ? runResponseRef?.current : JSON.parse(runResponseRef?.current)}
                                errorMessage={runResponseRef?.current ? runResponseRef?.current.errorMessage : null}
                                runId={runIdUrlParamRef?.current} stageConfig={stageConfig} claimsData={claimsData} themeContext={themeContext}/>
                        </div>
                    </Row> : <>
                        {/*input bar for asins*/}
                        <Row width="100%" widths={["70%", "30%"]} alignmentVertical="bottom"
                             alignmentHorizontal="left">
                            <Row width="100%" widths={["40%", "20%"]} alignmentHorizontal="start"
                                 alignmentVertical="bottom"
                                 spacing="400" spacingInset="300">
                                <Textarea rows={1}
                                          id="input-asins"
                                          value={asinsRef.current}
                                          onChange={setAsins}
                                          type="text"
                                          helperText="for multiple asins separate them by comma"
                                          constraintText=""
                                    // errorMessage={""}
                                          error={asinInputError && asinInputError.length > 0}
                                          label="Enter asins to push"
                                          size="small"
                                          width="80%"
                                          disabled={asinInputDisabledRef.current}
                                />
                                {/*<div style={{marginBottom: "15px"}}>*/}
                                <Button onClick={pushAsinOnClick} type="tertiary" size="medium"
                                        disabled={asinInputDisabledRef.current}
                                        spacingInset="100">Push Asin </Button>
                                {/*</div>*/}
                            </Row>
                        </Row>
                        <Row></Row>
                        {/*invoked when button clicked or show the existing user jobs*/}
                        {formattedAsin && formattedAsin.length > 0 ?
                            <Row width="100%" widths={["100%"]} alignmentVertical="bottom"
                                 alignmentHorizontal="center"
                                 spacingInset="400">
                                <div className={css(fadeInStyles.fadeInAnimation)}>
                                    <PushInvokedView pushedAsin={formattedAsinRef}
                                                     setIsLinearLoading={setIsLinearLoading}
                                                     setIsAsinInputDisabled={setIsAsinInputDisabled}
                                                     normalAsins={normalAsinsRef?.current}
                                                     forceCreateJob={forceCreateJobRef?.current}/>
                                </div>
                            </Row> : <Row width="100%" widths={["100%"]} alignmentVertical="bottom"
                                          alignmentHorizontal="center"
                                          spacingInset="400">
                                <div className={css(fadeInStyles.fadeInAnimation)}>
                                    <UserJobs jobList={formatUserJobInfos(userJobsRef.current, stageConfig, claimsData)}
                                              stageConfig={stageConfig}
                                              currentPage={currentPage} setCurrentPage={setCurrentPage}
                                              userData={userData} claimsData={claimsData} themeContext={themeContext}/>
                                </div>
                            </Row>}
                    </>}

                </> : <Row width="100%" widths={["100%"]} alignmentVertical="center" alignmentHorizontal="center"
                           spacingInset="400">
                    <div style={{
                        justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column",
                    }}>
                        <LottieAnimation animationData={locked1}
                                         text={""}
                                         height={200}
                                         width={200}/>
                        <div className={css(fadeInStyles.fadeInAnimation)}>
                            <Column alignmentVertical="center" spacing="300" wrap="down">
                                <Text type="">You don't have access for this resource. You need to be a member
                                    of <Link
                                        href={"https://permissions.amazon.com/a/team/" + claimsData?.pushAsinGroup}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        type="primary">{claimsData?.pushAsinGroup + " "}
                                        team</Link>
                                </Text>
                                <Button onClick={() => setOpenFeedbackView(true)} type="tertiary" size="medium">Submit
                                    Permission Request</Button>
                            </Column>
                        </div>
                    </div>
                </Row>}

                <Feedback key={uuidv4()} openFeedback={openFeedBackView} setOpenFeedback={setOpenFeedbackView}
                          isDarkMode={themeContext.isDarkMode} title={`[${APP_NAME}] Access Request`}
                          body={`Requesting access to the \`${claimsData?.pushAsinGroup}\` team for user \`${claimsData?.userName}\``}/>
            </Column>}
    </>)
}

