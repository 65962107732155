import {Claims, ClaimsActionTypes, UPDATE_CLAIMS} from "./types";

const initialState: Claims = {
    hasPushAsinAccess: false,
    internalGroup: "group-007",
    isInternal: false,
    pushAsinGroup: "group-007",
    status: false,
    userName: "user-007",
    waitTimes: {
        maxCompleteWaitTime: "43200000",
        maxDependencyWaitTime: "1800000",
        maxSherlockWaitTime: "900000",
        maxDeleteWaitTime: "86400000"
    }
};

export function ClaimsReducer(
    state = initialState,
    action: ClaimsActionTypes
): Claims {
    switch (action.type) {
        case UPDATE_CLAIMS:
            return {
                ...state,
                ...action.payload,

            };
        default:
            return state;
    }
}