import React, {useState, useCallback} from "react";
import AppLayout from "@amzn/meridian/app-layout";
import Header from "src/components/layouts/Header";
import {HashRouter as Router, Switch, Route, Link} from "react-router-dom";
import {AppSideMenu} from "src/components/layouts/SideMenu";
import {PAGE_LOCATIONS} from "src/constants/app";
import {HomeView} from "src/components/layouts/home/Home";
import {BuzzWorldView} from "src/components/layouts/buzz/BuzzWorld";
import {PushHomeView} from "src/components/layouts/push/PushHome";


export class App extends React.Component {
    render() {
        return (
            <Router>
                <AppLayout headerComponent={Header} sidebarComponent={AppSideMenu} backgroundColor="alternatePrimary">
                    <Header/>
                    <AppSideMenu/>
                    <Switch>
                        <Route exact path={PAGE_LOCATIONS.HOME}>
                            <HomeView/>
                        </Route>
                        <Route exact path={PAGE_LOCATIONS.PUSH_ASIN}>
                            <PushHomeView/>
                        </Route>
                        <Route exact path={PAGE_LOCATIONS.BUZZ_WORLD}>
                            <BuzzWorldView/>
                        </Route>
                    </Switch>
                </AppLayout>
            </Router>
        );
    }
}

export default App;
