import Modal from "react-modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import closeLargeTokens from "@amzn/meridian-tokens/base/icon/close-large";
import React from "react";
import {getCustomModalStyle} from "src/util/customModalStyle";
import {useHistory} from "react-router-dom";
import {PAGE_LOCATIONS, PERM_MGMT} from "src/constants/app";


export const GeneralConfirmDialog = (props) => {
    const {
        openModal, setOpenModal, isDarkMode
    } = props
    const history = useHistory();

    return (<Modal
        key={'modal'}
        isOpen={openModal}
        // onRequestClose={() => setOpenModal(o => !o)}
        style={getCustomModalStyle(isDarkMode, "25%", "50%")}
    >
        <Row width="100%" widths={["95%", "5%"]}>
            <Text>&nbsp;</Text>
            {/*<Button type="icon" onClick={() => setOpenModal(false)}>*/}
            {/*    <Icon tokens={closeLargeTokens}/>*/}
            {/*</Button>*/}
        </Row>

        <Text type={"b400"}>
            This feature is currently in development, and the information provided may be inaccurate or out-of-date.
            Please exercise caution and trust your own judgment when using the data.
        </Text>
        <br/>
        <Text type="b300">You must accept the terms in order to continue.</Text>


        <div width="100%" style={{
            marginTop: "50px",
        }}>
            <Row width="100%" alignmentHorizontal={"end"}>
                <Button type="tertiary" onClick={() => history.push(PAGE_LOCATIONS.HOME)}>
                    Decline
                </Button>
                <Button type="tertiary" onClick={() => setOpenModal(false)}>
                    Accept
                </Button>
            </Row>
        </div>
    </Modal>)

}