import { combineReducers } from "redux";
import { UserReducer } from "src/reducers/user/reducer";
import { AppReducer } from "src/reducers/app/reducer";
import {ClaimsReducer} from "src/reducers/claims/reducer";

export const rootReducer = combineReducers({
    app: AppReducer,
    user: UserReducer,
    claims:ClaimsReducer
});

export type RootState = ReturnType<typeof rootReducer>;