import {DomainTypes, API_NAME, WEBSITE_URLS} from "src/constants/app";

export interface IAmplifyConfigs {
    COGNITO: {
        IDENTITY_POOL_ID: string;
    };
}

type AWSRegions =
    | "us-east-1"
    | "us-west-2"
    | "ap-southeast-1"
    | "eu-west-1"
    | "cn-north-1";


type ServiceEndpoint = {
    name: string;
    endpoint: string;
    region: AWSRegions;
};

type DateFormat = {
    locale: string;
    format: string;
};

export interface IAppStageConfig {
    AMPLIFY: IAmplifyConfigs;
    AWS_REGION: AWSRegions;
    REALM: "NA";
    REALM_NAME: "Global Beta" | "Global Prod";
    STAGE: DomainTypes;
    SERVICE_ENDPOINTS: Array<ServiceEndpoint>;
    DATE: DateFormat;
}

export const devConfig: IAppStageConfig = {
    AMPLIFY: {
        COGNITO: {
            IDENTITY_POOL_ID: "us-east-1:f1659a30-80c8-438c-b1bd-95e89c48c6f3",
        },
    },
    AWS_REGION: "us-east-1",
    REALM: "NA",
    REALM_NAME: "Global Prod",
    STAGE: "prod",
    SERVICE_ENDPOINTS: [
        {
            name: API_NAME,
            endpoint: "https://ls0pdsg1c5.execute-api.us-east-1.amazonaws.com/prod",
            region: "us-east-1",
        },
    ],
    DATE: {
        locale: "en-US",
        format: "yyyy-MM-dd HH:mm:ss"
    }
};


export const stageConfig: Record<string, IAppStageConfig> = {
        "https://kmarxlh.people.amazon.dev:4321": devConfig,
        [WEBSITE_URLS.beta.NA]: {
            AMPLIFY: {
                COGNITO: {
                    IDENTITY_POOL_ID: "us-east-1:c9ca5b38-18b2-4116-b482-a2a1f958507a",
                },
            },
            AWS_REGION: "us-east-1",
            REALM: "NA",
            REALM_NAME: "Global Beta",
            STAGE: "beta",
            SERVICE_ENDPOINTS: [
                {
                    name: API_NAME,
                    endpoint: "https://37ghybcqoh.execute-api.us-east-1.amazonaws.com/beta",
                    region: "us-east-1",
                },
            ],
            DATE: {
                locale: "en-US",
                format: "yyyy-MM-dd HH:mm:ss"
            }
        },
        [WEBSITE_URLS.prod.NA]:
            {
                AMPLIFY: {
                    COGNITO: {
                        IDENTITY_POOL_ID: "us-east-1:f1659a30-80c8-438c-b1bd-95e89c48c6f3",
                    }
                    ,
                }
                ,
                AWS_REGION: "us-east-1",
                REALM:
                    "NA",
                REALM_NAME: "Global Prod",
                STAGE:
                    "prod",
                SERVICE_ENDPOINTS:
                    [
                        {
                            name: API_NAME,
                            endpoint: "https://ls0pdsg1c5.execute-api.us-east-1.amazonaws.com/prod",
                            region: "us-east-1",
                        },
                    ],
                DATE:
                    {
                        locale: "en-US",
                        format:
                            "yyyy-MM-dd HH:mm:ss"
                    }
            }
    }
;

export const getCurrentStageConfig = (domain?: string): IAppStageConfig => {
    let config: IAppStageConfig;
    if (domain) {
        config = stageConfig[domain];
    } else {
        config = stageConfig[window.location.origin];
    }
    if (!config) {
        throw `Error Initialising Stage Config : ${domain}`;
    }
    return config;
};