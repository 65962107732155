import React, {useCallback, useEffect} from "react";
import useState from 'react-usestateref'
import Alert from "@amzn/meridian/alert"
import Modal from "react-modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import closeLargeTokens from "@amzn/meridian-tokens/base/icon/close-large";
import Textarea from "@amzn/meridian/textarea";
import Loader from "@amzn/meridian/loader";
import {submitFeedback} from "src/apis";
import {refreshMidway} from "src/authentication/midwayTokenRetriever";
import {PERM_MGMT, SIM_ROOT_DOMAIN, TT_LINK} from "src/constants/app";
import Link from "@amzn/meridian/link";
import {fadeInStyles, getCustomModalStyle} from "src/util/customModalStyle";
import {isEmpty} from "lodash";
import {css} from "aphrodite";

const MAX_TITLE_SIZE = 80;
const MAX_BODY_SIZE = 256;

export const Feedback = (props) => {
    if (process.env.NODE_ENV !== 'test')
        Modal.setAppElement('#root');


    const {openFeedback, setOpenFeedback, isDarkMode} = props
    const [title, setTitle, titleRef] = useState(props?.title || "")
    const [body, setBody, bodyRef] = useState(props?.body || "")
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)
    const [createdSimId, setCreatedSimId] = useState(false)

    const submitFeed = async () => {
        try {
            setIsSubmitting(true)
            setError(false)
            setErrorMessage("")
            if (isEmpty(bodyRef?.current) || bodyRef?.current.length < 10 || bodyRef?.current.length > MAX_BODY_SIZE)
                throw new Error(`The body must have 10 - ${MAX_BODY_SIZE} characters.`)
            if (isEmpty(titleRef?.current) || titleRef?.current.length < 5 || titleRef?.current.length > MAX_TITLE_SIZE)
                throw new Error(`The title must have 5 - ${MAX_TITLE_SIZE} characters.`)
            const credentials = await refreshMidway();
            const response = await submitFeedback(credentials.token, titleRef.current, bodyRef.current)
            if (response.errorMessage || !response?.status)
                throw new Error(response?.response || response.errorMessage)
            setFeedbackSubmitted(true)
            setCreatedSimId(response?.response)
        } catch (err) {
            setError(true);
            setErrorMessage(`${err.message} Please raise a sim manually here : ${TT_LINK}`)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <Modal
            isOpen={openFeedback}
            onRequestClose={() => setOpenFeedback(o => !o)}
            style={getCustomModalStyle(isDarkMode, "45%", "60%")}
        >
            <Row width="100%" widths={["95%", "5%"]}>
                <Text>&nbsp;</Text>
                <Button type="icon" onClick={() => setOpenFeedback(false)}>
                    <Icon tokens={closeLargeTokens}/>
                </Button>
            </Row>

            <Text type="h400">Have an issue ? Reach out to DA Vending Team</Text>
            <br/>
            {!feedbackSubmitted && isSubmitting && <Loader type="linear" size="small"/>}
            {!feedbackSubmitted && error && <Alert type="error">{errorMessage}</Alert>}
            {feedbackSubmitted &&
                <Alert type="success">Thank you for your valuable feedback. You can track your request <Link
                    href={SIM_ROOT_DOMAIN + createdSimId}
                    target="_blank"
                    rel="noopener noreferrer"
                    type="primary">in here.</Link></Alert>}
            <br/>
            {!feedbackSubmitted &&
                <Textarea rows={1} disabled={isSubmitting} value={titleRef?.current} onChange={setTitle}
                          placeholder="Subject of you issue."
                          constraintText={`max ${MAX_TITLE_SIZE} chars`}/>}
            {!feedbackSubmitted &&
                <div style={{marginTop: "15px"}}><Textarea disabled={isSubmitting} value={bodyRef?.current}
                                                           onChange={setBody}
                                                           placeholder="Describe the issue/feedback in details."
                                                           constraintText={`max ${MAX_BODY_SIZE} chars`}/></div>}
            <br/>
            <Text type="b300">Want to reach out to us? Use <Link href={PERM_MGMT} target="_blank"
                                                                 rel="noopener noreferrer"
                                                                 type="secondary">this
                sim.</Link></Text>
            <br/><br/>
            <Row alignmentHorizontal={"end"} widths="fit">
                <Button
                    type="secondary"
                    size="small"
                    onClick={() => setOpenFeedback(false)}
                >
                    Close
                </Button>
                {!feedbackSubmitted &&
                    <Button disabled={isSubmitting} type="primary" size="small" onClick={() => submitFeed()}>
                        Submit
                    </Button>}
            </Row>
        </Modal>
    )
}