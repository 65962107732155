import {Cache} from "aws-amplify";
import {getTokenOrRedirect} from "./tokenRetriever";
import {FederatedResponse} from "@aws-amplify/auth/lib/types/Auth";

export interface IMidwayUserData {
    username: string;
    name: string;
}

const deleteQueryParameter = function (key: string) {
    const queryParams = new URLSearchParams(window.location.search);

    if (!queryParams.get(key)) {
        return;
    }
    queryParams.delete(key);
    const newUrl = new URL(window.location.href);

    newUrl.search = queryParams.toString();
    window.history.replaceState({}, "", newUrl.toString());
};

const parseJwt = (token: string) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (err) {
        console.error(`Error occurred during parsing JWT ${err}`);
    }
};

const parseUser = function (token: string): IMidwayUserData {
    const parsedToken = parseJwt(token);

    return {
        username: parsedToken.sub,
        name: parsedToken.sub
    };
};

export const getMidwayToken = async function (): Promise<string> {
    const cachedFederatedInfo = Cache.getItem("federatedInfo");
    const currentTimestamp = Date.now();

    let midwayToken: string;
    const oneSecond = 1000;

    if (
        cachedFederatedInfo &&
        currentTimestamp < cachedFederatedInfo.expires_at * oneSecond
    ) {
        midwayToken = cachedFederatedInfo.token;
    } else {
        midwayToken = await getTokenOrRedirect();
    }
    deleteQueryParameter("id_token");
    deleteQueryParameter("state");
    return midwayToken;
};

export let refreshMidway = async function (): Promise<FederatedResponse> {
    const token = await getMidwayToken();
    const parsedToken = parseJwt(token);
    return {
        token,
        expires_at: parsedToken.exp
    };
};

export const getUsername = async function getUsername(
    data: FederatedResponse
): Promise<IMidwayUserData> {
    const token = data.token || Cache.getItem("federatedInfo").token;

    return parseUser(token);
};