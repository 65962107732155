import {StyleSheet, css} from 'aphrodite';
import {fadeIn} from 'react-animations';

export const getCustomModalStyle = (isDarkMode = false, height = "40%", width = "60%") => {
    return {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            height: "auto",
            width: width,
            // overflow: 'scroll',
            "backgroundColor": isDarkMode ? "#252829" : "white"
        },
        overlay: {
            zIndex: 10
        }
    };
}


export const fadeInStyles = StyleSheet.create({
    fadeInAnimation: {
        animationName: fadeIn,
        animationDuration: '2s'
    }
});