import React, {useState, useContext} from "react"
import SideMenu, {SideMenuTitle, SideMenuLink} from "@amzn/meridian/side-menu"
import Row from "@amzn/meridian/row"
import homeTokens from "@amzn/meridian-tokens/base/icon/home"
import mergeTokens from "@amzn/meridian-tokens/base/icon/merge"
import flashTokens from "@amzn/meridian-tokens/base/icon/flash"
import Text from "@amzn/meridian/text";
import {useHistory, useLocation} from "react-router-dom";
import {PAGE_LOCATIONS} from "src/constants/app";

export const AppSideMenu = (props) => {
    const [open, setOpen] = useState(true)
    let history = useHistory();
    let location = useLocation();

    const onClose = () => setOpen(false)
    const onOpen = () => setOpen(true)
    return (
        <Row alignmentVertical="stretch" spacing="none">
            <SideMenu open={open} onClose={onClose} onOpen={onOpen} type="skinny">

                <SideMenuTitle href={PAGE_LOCATIONS.HOME} onClick={history.push}>
                    <Text type="h200">Menu</Text>
                </SideMenuTitle>
                <SideMenuLink
                    href={PAGE_LOCATIONS.HOME}
                    selected={location.pathname === PAGE_LOCATIONS.HOME}
                    onClick={history.push}
                    icon={homeTokens}
                >
                    Home
                </SideMenuLink>
                <SideMenuLink
                    href={PAGE_LOCATIONS.PUSH_ASIN}
                    selected={location.pathname === PAGE_LOCATIONS.PUSH_ASIN}
                    onClick={history.push}
                    icon={mergeTokens}
                >
                    Push Asin
                </SideMenuLink>
                <SideMenuLink
                    href={PAGE_LOCATIONS.BUZZ_WORLD}
                    selected={location.pathname === PAGE_LOCATIONS.BUZZ_WORLD}
                    onClick={history.push}
                    collapsedIcon={flashTokens}
                    icon={flashTokens}
                >
                    Buzz World
                </SideMenuLink>
            </SideMenu>
        </Row>
    )
}