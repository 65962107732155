import {API} from "aws-amplify";
import {API_NAME, PUSH_ASIN_PROFILE_ID} from "src/constants/app";
import {v4 as uuidv4} from 'uuid';


export const getCradleJobs = async (credentials) => {
    try {
        const path = '/cradle';
        const body = {
            body: {
                "token": credentials,
                "action": "GET_JOBS",
            }
        };
        const response = await API.post(API_NAME, path, body)
        if (response?.status && response?.response)
            return JSON.parse(response.response)
        else if (!response?.status)
            throw new Error(response?.response)
        else return []
    } catch (error) {
        console.log(`Error while calling getCradleJobs API : ${error}`)
        return {"errorMessage": error.message}
    }
}


export const invokedCradleAction = async (credentials, username, action, cradleJobId, asins, runId, requestingUser = null) => {
    try {
        const path = '/cradle';
        const body = {
            body: {
                "token": credentials,
                "action": action,
                "profileId": PUSH_ASIN_PROFILE_ID,
                "jobId": cradleJobId,
                "runId": runId,
                "jobName": `push-asin-job-${username}`,
                "requestingUser": requestingUser,
                "variables":
                    {
                        "marketplace_id": "6",
                        "input_asin": asins,
                        "output_s3_key": "gv-inference/inference-input/manual-asin/",
                        "output_s3_bucket": "sherlockv2-client-resources-series-prod",
                        "region_id": "3",
                        "resource": "mediumEMR5.2PooledEdxEMRHiveResource",
                        "clusterSize": "mediumEMR5.28"
                    }
            }
        };
        const response = await API.post(API_NAME, path, body)
        if (response?.status && response?.response)
            return response.response
        else if (!response?.status)
            throw new Error(response?.response)
    } catch (error) {
        console.log(`Error while calling invokedCradleAction API : ${error}`)
        return {"errorMessage": error.message}
    }
}
