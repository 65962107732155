import {Cache} from "aws-amplify";

export const AUTHENTICATION_PROVIDER = "midway-auth.amazon.com";
export const PAGE_LOCATIONS = {
    HOME: "/",
    PUSH_ASIN: "/push-asin",
    BUZZ_WORLD: "/buzz-world",
};
export type DomainTypes = "beta" | "prod";
export const APP_NAME = "DA Vending Central"
export const BUZZ_WORLD_DESTINATION = "https://r4a.dccs.amazon.dev/"
export type RegionTypes = "NA";
export const PUSH_ASIN_FORCE_CREATE_URL_PARAM = "forceCreateJob"
export const PUSH_ASIN_URL_PARAM = "asins"
export const PUSH_ASIN_RUN_ID_PARAM = "runId"
export const PUSH_ASIN_REQUESTING_USER_PARAM = "requestingUser"
export const PUSH_ASIN_PROFILE_ID = "b8d3a326-0e65-4641-950a-b99671e87d99"
export const SIM_ROOT_DOMAIN = "https://issues.amazon.com/"

export const WEBSITE_URLS: Record<DomainTypes, Record<RegionTypes, string>> = {
    beta: {
        NA: "https://davending-sandbox.digital.amazon.dev",
    },
    prod: {
        NA: "https://davending.digital.amazon.dev",
    },
};

export const DELAY = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const API_NAME = "DA_VENDING_API";
export const FAQ_LINK =
    "https://w.amazon.com/bin/view/Spark/Sherlock/";
export const TT_LINK =
    "https://issues.amazon.com/issues/create?assignedFolder=1177ced8-6bd4-4b3b-b4b9-9925ed93623b";
export const PERM_MGMT =
    "https://issues.amazon.com/issues/create?assignedFolder=1177ced8-6bd4-4b3b-b4b9-9925ed93623b";


export enum DATE_FORMAT_TYPE {
    DATE = "DATE",
    DATE_TIME = "DATETIME"
}
