import React, {useContext, useEffect, useCallback} from "react"
import useState from 'react-usestateref'
import {useSelector} from "react-redux";
import Column from "@amzn/meridian/column";
import {useHistory, useLocation,} from "react-router-dom";
import {css} from 'aphrodite';

import {refreshMidway} from "src/authentication/midwayTokenRetriever";
import Button from "@amzn/meridian/button";
import Alert from "@amzn/meridian/alert";
import {
    DELAY,
    PAGE_LOCATIONS,
    PUSH_ASIN_FORCE_CREATE_URL_PARAM,
    PUSH_ASIN_RUN_ID_PARAM,
    PUSH_ASIN_URL_PARAM
} from "src/constants/app";

import ProgressTracker, {ProgressTrackerStep,} from "@amzn/meridian/progress-tracker"
import Text from "@amzn/meridian/text";
import {getCradleJobs, invokedCradleAction} from "src/apis/pushAsin";
import {fadeInStyles} from "src/util/customModalStyle";
import {v4 as uuidv4} from "uuid";
import Row from "@amzn/meridian/row";
import {isEmpty} from "lodash";
import {GET_RUN_ID_URL} from "src/components/layouts/push/UserJobsConstants";
import loading2 from "src/animations/loading3.json";
import {LottieAnimation} from "src/components/common/LottieAnimation";

const DEFAULT_WAIT_TIME = 20000
const CRADLE_STATUSES = {
    FORCE_JOB_RUN_DEPENDENCIES: "FORCE_JOB_RUN_DEPENDENCIES",
    CRADLE_WAITING_FOR_DEPENDENCIES_STATUS: "WAITING_FOR_DEPENDENCIES",
    CREATE_JOB: "CREATE_JOB",
    CREATE_JOB_RUN: "CREATE_JOB_RUN",
    GET_JOB_RUN_STATUS: "GET_JOB_RUN_STATUS",
    SUCCESS_STATUS: "SUCCESS",
    LAST_STAGE: "FINAL_STATUS",
    NON_TERMINAL_STATUS_LIST: ["RESOURCES_ACQUIRED", "RUNNING", "RESOURCES_RELEASED", "WAITING_FOR_RESOURCES", "WAITING_FOR_DEPENDENCIES"]
}

export const PushInvokedView = (props) => {
    const history = useHistory();
    const userNameSelector = (state) => state.user;
    const userData = useSelector(userNameSelector);
    const {setIsLinearLoading, setIsAsinInputDisabled, pushedAsin, normalAsins, forceCreateJob} = props

    const [push_asin_api_steps, set_push_asin_api_steps] = useState([{
        "action": "CREATE_JOB",
        "displayName": "Create Job",
        "description": "create the job for push asin profile",
        "type": "present",
        "isNoOp": false,
        "statusStage": false,
        "eta": "30 sec"
    }, {
        "action": "CREATE_JOB_RUN",
        "displayName": "Invoke Run",
        "description": "initiate a job run for the job",
        "type": "future",
        "isNoOp": false, "statusStage": false, "eta": "30 sec"
    }, {
        "action": "GET_JOB_RUN_STATUS",
        "displayName": "Get Status",
        "description": "fetching the job status",
        "type": "future",
        "repeat": 10,
        "delay": 20000,
        "isNoOp": false, "statusStage": true,
        "eta": "3 min"
    }, {
        "action": "FORCE_JOB_RUN_DEPENDENCIES",
        "displayName": "Dependency",
        "description": "force the job run dependencies",
        "type": "future",
        "isNoOp": false, "statusStage": false,
        "eta": "15 sec"
    }, {
        "action": "GET_JOB_RUN_STATUS",
        "displayName": "Get Status",
        "description": "fetching the job status",
        "type": "future",
        "isNoOp": false, "statusStage": true,
        "eta": "15 sec"
    }, {
        "action": "FINAL_STATUS",
        "displayName": "End",
        "description": "fetching the final status",
        "type": "future",
        "isNoOp": true, "statusStage": false, "eta": "15 sec"
    }])

    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [getJobStatusCount, setGetJobStatusCount] = useState(0);
    const [errorMessage, setErrorMessage, errorMessageRef] = useState("")
    const [isSuccess, setIsSuccess, isSuccessRef] = useState(false)
    const [isLastStage, setIsLastStage, isLastStageRef] = useState(false)
    const [progressStatus, setProgressStatus, progressStatusRef] = useState("none")

    const [cradleJobId, setCradleJobId, cradleJobIdRef] = useState("")
    const [runId, setRunId, runIdRef] = useState("")
    const [lastCradleStatus, setLastCradleStatus, lastCradleStatusRef] = useState("")


    /**
     * actual api call
     * @param action
     * @returns {Promise<void>}
     */
    const callApi = async (action) => {
        try {
            setIsLinearLoading(true)
            setIsAsinInputDisabled(true)

            // Make API call based on the action
            const credentials = await refreshMidway();
            const current_step = push_asin_api_steps[currentStepIndex]
            console.log(`[${currentStepIndex} of ${push_asin_api_steps.length}] executing : `, current_step)
            current_step.type = "present"
            set_push_asin_api_steps([...push_asin_api_steps])

            if (isEmpty(pushedAsin?.current)) {
                throw new Error("asins to push cannot be empty.")
            }

            let response = {}
            if (current_step.action === CRADLE_STATUSES.FORCE_JOB_RUN_DEPENDENCIES && lastCradleStatusRef?.current !== CRADLE_STATUSES.CRADLE_WAITING_FOR_DEPENDENCIES_STATUS) {
                console.log("skipping force run dependencies as last status was : ", lastCradleStatusRef?.current)
            } else if (current_step?.isNoOp) {
                console.log("no op action : ", current_step)
            } else {
                response = await invokedCradleAction(credentials.token, userData?.username, current_step.action, cradleJobIdRef.current, pushedAsin?.current, runIdRef?.current);
            }
            if (current_step?.action === CRADLE_STATUSES.LAST_STAGE) {
                setIsLastStage(true)
            }
            //handle all responses
            if (response?.errorMessage) {
                throw new Error(response?.errorMessage);
            }
            //update run id from response of create_job
            if (!isEmpty(response) && current_step.action === CRADLE_STATUSES.CREATE_JOB) {
                setRunId(JSON.parse(response)?.runId)
            }//handle cradle statuses
            else if (!isEmpty(response) && current_step.action === CRADLE_STATUSES.GET_JOB_RUN_STATUS) {
                setLastCradleStatus(response)
                if (CRADLE_STATUSES.NON_TERMINAL_STATUS_LIST.includes(response)) {
                    console.log("non terminal status from cradle , go to next steps : ", response)
                } else if (response === CRADLE_STATUSES.SUCCESS_STATUS) {
                    console.log("job run success")
                    setProgressStatus("none")
                    setIsSuccess(true)
                } else {
                    setProgressStatus("warning")
                }
            }
            if (!isSuccessRef.current && current_step?.delay) {
                console.log(`waiting for ${current_step?.delay} ms`)
                await DELAY(current_step.delay || DEFAULT_WAIT_TIME)
            }
            if (!isSuccessRef.current && current_step?.repeat && getJobStatusCount < current_step?.repeat - 1) {
                setGetJobStatusCount(prevCount => prevCount + 1);
                setCurrentStepIndex(prevIndex => prevIndex);
            } else {
                if (currentStepIndex < push_asin_api_steps.length - 1) {
                    current_step.type = "past"
                    set_push_asin_api_steps([...push_asin_api_steps])
                    setCurrentStepIndex(prevIndex => prevIndex + 1);
                    setGetJobStatusCount(0);
                } else {
                    console.log("all steps done")
                }
            }
            if (isLastStageRef.current && !isSuccessRef.current) {
                setProgressStatus("warning")
            }
        } catch (error) {
            console.log("error in push_asin call : ", error)
            setErrorMessage(error.message)
            setProgressStatus("error")
            setIsLinearLoading(false)
            setIsAsinInputDisabled(false)
        } finally {
            console.log("inside finally")
            if (isLastStageRef.current) {
                setIsLinearLoading(false)
                setIsAsinInputDisabled(false)
            }
        }
    };


    useEffect(async () => {
        //if it's 0 index fetch the job profile id
        if (currentStepIndex === 0) {
            if (!forceCreateJob) {
                const credentials = await refreshMidway();
                const allJobs = await getCradleJobs(credentials.token);
                if (allJobs && allJobs.errorMessage) {
                    setErrorMessage(allJobs.errorMessage)
                } else if (allJobs && allJobs.length > 0) {
                    setCradleJobId(allJobs[0]["jobId"])
                }
            }
        }
        const currentStep = push_asin_api_steps[currentStepIndex];
        if (currentStep) {
            callApi(currentStep.action);
        }
    }, [currentStepIndex, getJobStatusCount])


    return (<>

        <Column heights="fit" spacing="300" spacingInset="400" wrap="down" alignmentHorizontal="start"
                alignmentVertical="top">
            <Text type="b200">Pushing asin → <strong>{pushedAsin?.current}</strong></Text>
            <div style={{
                width: '100%', marginBottom: "40px", justifyContent: "center", alignItems: "start", display: "flex",
            }}>
                {errorMessageRef.current && errorMessageRef.current.length > 0 && (
                    <Row spacing="none" spacingInset="200">
                        <Alert
                            type="error"
                        >{errorMessageRef.current} {" "}
                            {errorMessageRef?.current.includes("did you mean to create instead") &&
                                <Button onClick={() => {
                                    history.push({
                                        pathname: PAGE_LOCATIONS.PUSH_ASIN, search: `?${new URLSearchParams({
                                            [PUSH_ASIN_URL_PARAM]: normalAsins?.current,
                                            [PUSH_ASIN_FORCE_CREATE_URL_PARAM]: true
                                        })}`
                                    })
                                    window.location.reload();
                                }} type="link" size="small">Force Create</Button>}
                        </Alert>
                    </Row>)}
            </div>
            <div style={{
                width: '100%',
                height: '100px',
                justifyContent: "center",
                alignItems: "start",
                display: "flex",
                marginTop: "100px",
                marginBottom: "100px"
            }}>
                <ProgressTracker
                    type="theme"
                    compact={true}
                    direction="row"
                >
                    {push_asin_api_steps.map(step => {
                        return <ProgressTrackerStep key={uuidv4()}
                                                    type={step.type}
                                                    label={<strong>{step.displayName}</strong>}
                                                    showLabel={true}
                                                    trackLength={14}
                                                    alert={progressStatusRef?.current}
                        >
                            <div style={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex", flexDirection: "column",
                            }}>
                                <Text type="b200">ETA : {step?.eta}</Text>
                                {isLastStageRef?.current && lastCradleStatusRef?.current}
                            </div>
                        </ProgressTrackerStep>
                    })}
                </ProgressTracker>
            </div>

            <div style={{
                width: '100%',
                marginBottom: "40px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
            }}>
                <div className={css(fadeInStyles.fadeInAnimation)}>
                    {
                        !(errorMessageRef.current && errorMessageRef.current.length > 0) ?
                            (isLastStageRef?.current ?
                                    (
                                        <div style={{
                                            width: '100%',
                                            justifyContent: "start",
                                            alignItems: "start",
                                            display: "flex",
                                        }}>
                                            <Text type="b300">
                                                {isSuccessRef.current ? "The cradle job has been successfully completed. Inference Workflow is in progress which might take 30 minutes to complete. You can check the final status by clicking here ." : "Looks like the cradle job is taking longer than expected. Please wait for at least 30 minutes before checking the status again by clicking here "}
                                            </Text>
                                            <Button onClick={() => {
                                                window.open(GET_RUN_ID_URL(runIdRef?.current, userData?.username), "_self", "noopener,noreferrer");
                                                window.location.reload();
                                            }} type="tertiary" size="small">Check Status</Button>
                                        </div>
                                    ) : (
                                        <div style={{
                                            width: '100%',
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }}>
                                            <LottieAnimation animationData={loading2}
                                                             text={lastCradleStatusRef?.current || "Running ..."}
                                                             height={200}
                                                             width={200}/>
                                        </div>
                                    )
                            ) : ""
                    }
                </div>
            </div>

        </Column>
    </>)
}