
export interface UserState {
    username: string;
    name?: string;
}

export const UPDATE_USER_DATA: string = "@@USER/UPDATE_USER";

interface UPDATE_USER_ACTION {
    type: typeof UPDATE_USER_DATA;
    payload: UserState;
}

export type UserActionTypes = UPDATE_USER_ACTION;