import orangeDarkTokens, {BlueDarkTokens} from '@amzn/meridian-tokens/theme/blue-dark';
import orangeLightTokens, {BlueLightTokens} from '@amzn/meridian-tokens/theme/blue-light';
import Theme from '@amzn/meridian/theme';
import React, {ReactElement, useContext} from 'react';
import {useStateWithLocalStorage} from '../util/localStorage';

export interface ThemeContextInterface {
    isDarkMode: boolean;
    setDarkMode: (isDarkMode: boolean) => void;
}

export type PortalTokens = BlueDarkTokens | BlueLightTokens;

export const ThemeContext = React.createContext<ThemeContextInterface>({
    isDarkMode: false,
    setDarkMode: () => undefined,
});


function getTokens(isDarkMode: boolean): PortalTokens {
    return isDarkMode ? orangeDarkTokens : orangeLightTokens;
}


export function useThemeTokens(): PortalTokens {
    const {isDarkMode} = useContext(ThemeContext);
    return getTokens(isDarkMode);
}


export function ThemeProvider({children}: { children: ReactElement }): JSX.Element {
    const [isDarkMode, setDarkMode] = useStateWithLocalStorage('isDarkMode', true);
    // If we want to get really fancy we can also expose a toggle for the theme color...

    return (
        <ThemeContext.Provider value={{isDarkMode, setDarkMode}}>
            <Theme tokens={getTokens(isDarkMode)}>{children}</Theme>
        </ThemeContext.Provider>
    );
}