import React, {useContext, useState} from 'react';
import {BUZZ_WORLD_DESTINATION} from "src/constants/app";
import {GeneralConfirmDialog} from "src/components/layouts/buzz/GeneralConfirmDialog";
import {ThemeContext} from "src/context/ThemeContext";
import loading1 from "src/animations/loading1.json";
import {LottieAnimation} from "src/components/common/LottieAnimation";

export const BuzzWorldView = (props) => {
    const themeContext = useContext(ThemeContext);
    const [openConsentModal, setConsentModal] = useState(true)
    const [showLoader, setShowLoader] = useState(true)

    return (<div style={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    }}>
        <GeneralConfirmDialog openModal={openConsentModal} setOpenModal={setConsentModal}
                              isDarkMode={themeContext.isDarkMode}/>
        {!openConsentModal && <iframe
            title=""
            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1}}
            src={BUZZ_WORLD_DESTINATION}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            onLoad={() => setShowLoader(false)}
            onLoadStart={() => setShowLoader(true)}
        />}
        {showLoader && <div style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 2
        }}>
            <div>
                <LottieAnimation animationData={loading1}
                                 text={"Loading..."}
                                 height={150}
                                 width={150}/>
            </div>
        </div>}
    </div>);
};