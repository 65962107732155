import React, {useState, useContext} from "react"
import Row from "@amzn/meridian/row"
import Heading from "@amzn/meridian/heading";
import {PAGE_LOCATIONS} from "src/constants/app";
import {LottieAnimation} from "src/components/common/LottieAnimation";
import welcome from "src/animations/welcome.json";
import {useSelector} from "react-redux";
import {TypeAnimation} from "react-type-animation";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import {useHistory,} from "react-router-dom";
import {css} from 'aphrodite';
import {fadeInStyles} from "src/util/customModalStyle";
import {Feedback} from "src/components/layouts/Feedback";
import {v4 as uuidv4} from "uuid";
import {ThemeContext} from "src/context/ThemeContext";


export const HomeView = (props) => {
    const userNameSelector = (state) => state.user;
    const userData = useSelector(userNameSelector);
    const history = useHistory();
    const themeContext = useContext(ThemeContext);
    const [showButton, setShowButton] = useState(false);
    const [openFeedBackView, setOpenFeedbackView] = useState(false);

    return (<Row alignmentVertical="stretch" spacing="none" spacingInset="400" wrap="up">
        <Column heights="fit" spacing="medium">
            <LottieAnimation animationData={welcome}
                             text={""}
                             height={100}
                             width={200}/>
        </Column>
        <Column heights="fit" spacing="medium">
            <Heading level={3} type="d50">
                <TypeAnimation
                    sequence={[500, `Welcome ${(userData && userData.username) || ''} to DA Vending Services`, 1000, `Welcome ${(userData && userData.username) || ''} to ADGDiscoveryService`, 1000, `Welcome ${(userData && userData.username) || ''} to Sherlock`, 1000]}
                    speed={50}
                    style={{fontSize: '22px', fontWeight: 'normal', fontFamily: 'monospace'}}
                    repeat={Infinity}
                />
            </Heading>

            <Heading level={3} type="b400">
                <TypeAnimation
                    // splitter={(str) => str.split(/(?= )/)}
                    sequence={[
                        3000,
                        `\n\nWhat we Own ?\n\nADGDiscoveryService\nIt provides APIs that enable our customers to get the data they want in the form that they want it.\n\nSherlock\nIt is a relationship identification system that uses learnt systems to determine related items within or across digital catalogs.\nIt supports inference on Sagemaker, provides persistent relationships with relationship history, governed audits,\nquality metrics, and integrations with the digital catalog systems.`,
                        1000,
                        () => {
                            setShowButton(true);
                        },
                    ]}
                    // speed={{type: 'keyStrokeDelayInMs', value: 200}}
                    speed={80}
                    // omitDeletionAnimation={true}
                    style={{
                        fontSize: '16px', fontWeight: 'normal', whiteSpace: 'pre-line', fontFamily: 'sans-serif'
                    }}
                    repeat={1}
                />
            </Heading>
            <div style={{padding: "20px"}}/>
            {showButton &&
                <div className={css(fadeInStyles.fadeInAnimation)}>
                    <Row alignmentVertical="stretch" spacing="300" wrap="down">
                        <Button onClick={() => {
                            history.push(`${PAGE_LOCATIONS.BUZZ_WORLD}`)
                        }} type="tertiary" size="medium">Head
                            to Buzz World</Button>

                        <Button onClick={() => setOpenFeedbackView(true)} type="tertiary" size="medium">Submit
                            Feedback</Button>
                    </Row>
                </div>
            }
            <Feedback key={uuidv4()} openFeedback={openFeedBackView} setOpenFeedback={setOpenFeedbackView}
                      isDarkMode={themeContext.isDarkMode}/>
        </Column>

    </Row>)
}