import React from "react";
import useState from 'react-usestateref'
import Column from "@amzn/meridian/column"
import Alert from "@amzn/meridian/alert"

import Row from "@amzn/meridian/row"
import {format, parse} from 'date-fns'

import {isEmpty, toInteger, keys, values,} from "lodash";

import Text from "@amzn/meridian/text";
import {MILLISECONDS_TO_HOURS, renameKeys} from "src/components/layouts/push/UserJobsConstants";
import {Feedback} from "src/components/layouts/Feedback";
import {v4 as uuidv4} from "uuid";
import {APP_NAME} from "src/constants/app";
import Button from "@amzn/meridian/button";


export const JobRunInfo = (props) => {

    const {
        errorMessage, runResponse: {pushAsinAuditRecord}, runId, stageConfig, claimsData, themeContext
    } = props

    const [_openFeedBackView, _setOpenFeedbackView] = useState(false);
    const [_body, _setBody, _bodyRef] = useState("");

    const getCurrentJobStatus = (currentStatus, cradleStatus) => {
        if (cradleStatus === 'SUCCESS' && currentStatus === 'CREATE_JOB_RUN') {
            return 'SHERLOCK_PENDING'
        } else if (currentStatus === 'CREATE_JOB_RUN') {
            return cradleStatus
        }
        return currentStatus
    }
    const is_breached_sla = (pushAsinAuditRecord) => {
        const time_passed = toInteger(Date.now()) - toInteger(pushAsinAuditRecord?.createdAt)
        return getCurrentJobStatus(pushAsinAuditRecord?.currentStatus, pushAsinAuditRecord?.cradleJobStatus) !== 'SUCCEEDED' && time_passed > claimsData?.waitTimes?.maxCompleteWaitTime
    }
    const is_not_succeeded = (pushAsinAuditRecord) => {
        return pushAsinAuditRecord?.currentStatus !== 'SUCCEEDED'
    }

    return (<>
            <Text type="b200">Run Id → <strong>{runId}</strong></Text>

            {!isEmpty(errorMessage) ? (
                    <div
                        style={{
                            width: '100%',
                            marginTop: "40px",
                            justifyContent: "start",
                            alignItems: "start",
                            display: "flex",
                        }}>
                        <Row width="100%" widths={["grid-12"]} alignmentHorizontal="left">
                            <Alert
                                type="error"
                                size="large"
                            >{errorMessage}</Alert></Row>
                    </div>) :
                <div style={{borderRadius: "10px", border: "1px solid #ccc", padding: "10px", marginTop: "40px"}}>
                    <React.Fragment>
                        <Column className={"small"} width="100%" alignmentHorizontal="start">
                            <div style={{
                                width: '100%',
                                justifyContent: "center",
                                alignItems: "start",
                                display: "flex",
                                flexDirection: "column",
                            }}>
                                <Text type="b400"><strong>Current Status
                                    : {getCurrentJobStatus(pushAsinAuditRecord?.currentStatus, pushAsinAuditRecord?.cradleJobStatus)}</strong></Text>
                                {is_breached_sla(pushAsinAuditRecord) ?
                                    <Button size="small" type="tertiary"
                                            onClick={() => {
                                                _setBody(`The below job has breached the SLA of ${MILLISECONDS_TO_HOURS(claimsData?.waitTimes?.maxCompleteWaitTime)} hrs. Please debug and help it unblock.\n\nUser Id: ${pushAsinAuditRecord["userId"]}\nRun Id: ${pushAsinAuditRecord["runId"]}\nAsins: ${pushAsinAuditRecord["asins"]}\nCurrent Status: ${pushAsinAuditRecord["currentStatus"]}\nCradle Status: ${pushAsinAuditRecord["cradleJobStatus"]}\nCreated On: ${pushAsinAuditRecord["createdAt"]}\nUpdated On: ${pushAsinAuditRecord["updatedAt"]}`)
                                                _setOpenFeedbackView(true)
                                            }}>Contact
                                        Team</Button> :
                                    is_not_succeeded(pushAsinAuditRecord) ?
                                        <Button size="small" type="tertiary"
                                                onClick={() => {
                                                    _setBody(`Please enter your query here.----------------\n\nUser Id: ${pushAsinAuditRecord["userId"]}\nRun Id: ${pushAsinAuditRecord["runId"]}\nAsins: ${pushAsinAuditRecord["asins"]}\nCurrent Status: ${pushAsinAuditRecord["currentStatus"]}\nCradle Status: ${pushAsinAuditRecord["cradleJobStatus"]}\nCreated On: ${pushAsinAuditRecord["createdAt"]}\nUpdated On: ${pushAsinAuditRecord["updatedAt"]}`)
                                                    _setOpenFeedbackView(true)
                                                }}>Contact
                                            Team</Button>:
                                        ""}
                            </div>
                            <div style={{
                                width: '100%',
                                justifyContent: "center",
                                alignItems: "start",
                                display: "flex",
                                flexDirection: "column",
                            }}>
                                <Text><strong>Current Job Status
                                    : </strong> {getCurrentJobStatus(pushAsinAuditRecord?.currentStatus, pushAsinAuditRecord?.cradleJobStatus)}
                                </Text>
                                <Text><strong>Cradle Status
                                    : </strong> {pushAsinAuditRecord?.cradleJobStatus}</Text>
                                <Text><strong>Reason
                                    : </strong> {pushAsinAuditRecord?.reason}</Text>

                                {/*<Text><strong>Asins*/}
                                {/*    : </strong>*/}
                                {/*    <div style={{width: "100%", overflowWrap: "anywhere"}}>{pushAsinAuditRecord?.asins}*/}
                                {/*    </div>*/}
                                {/*</Text>*/}
                                <Text><strong>Profile Id
                                    : </strong> {pushAsinAuditRecord?.profileId}</Text>
                                <Text><strong>Job Id
                                    : </strong> {pushAsinAuditRecord?.jobId}</Text>
                                <Text><strong>Job Run Id
                                    : </strong> {pushAsinAuditRecord?.jobRunId}</Text>
                                <Text><strong>Last Update Actor
                                    : </strong> {pushAsinAuditRecord?.actor}</Text>
                                <Text><strong>Created At
                                    : </strong> {format(new Date(toInteger(pushAsinAuditRecord?.createdAt)), stageConfig.DATE.format)}
                                </Text>
                                <Text><strong>Updated At
                                    : </strong> {format(new Date(toInteger(pushAsinAuditRecord?.updatedAt)), stageConfig.DATE.format)}
                                </Text>
                                <Text><strong>Asin Map : </strong>{!pushAsinAuditRecord?.asinIdentityMap ?
                                    <i>Status will be updated
                                        after {MILLISECONDS_TO_HOURS(claimsData?.waitTimes?.maxSherlockWaitTime)} hrs</i> : ""}
                                </Text>
                                {pushAsinAuditRecord?.asinIdentityMap &&
                                    <ul>
                                        {Object.keys(JSON.parse(pushAsinAuditRecord?.asinIdentityMap)).map(key => {
                                            const value = JSON.parse(pushAsinAuditRecord?.asinIdentityMap)[key]
                                            return value === 'NA' ? <li><Text>{key} - Not Found</Text></li> :
                                                <li><Text>{key} - {value}</Text></li>
                                        })}
                                    </ul>}

                            </div>

                        </Column>
                        <Feedback key={uuidv4()} openFeedback={_openFeedBackView} setOpenFeedback={_setOpenFeedbackView}
                                  isDarkMode={themeContext.isDarkMode}
                                  title={`[${APP_NAME}] Push Asin Job Debug Request`}
                                  body={_bodyRef?.current}/>
                    </React.Fragment>
                </div>}
        </>

    );
};