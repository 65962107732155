import React, {useCallback, useEffect, useRef} from "react";
import useState from 'react-usestateref'
import Pagination from "@amzn/meridian/pagination"
import Column from "@amzn/meridian/column"
import Alert from "@amzn/meridian/alert"
import Table, {
    TableRow, TableCell, TableActionBar, TableActionBarOverlay,
} from "@amzn/meridian/table"
import Toggle from "@amzn/meridian/toggle"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import {format, parse} from 'date-fns'
import useWindowDimensions from "src/components/common/Dimension";

import Toaster from "@amzn/meridian/toaster";
import {isEmpty, toInteger, keys,} from "lodash";
import {
    dateColumns,
    extraItems, GET_RUN_ID_URL,
    hiddenItems, MAX_ALLOWED_LENGTH,
    MAX_JOBS_PAGE_COUNT, MAX_LENGTH_EXCLUDED_FIELDS, MILLISECONDS_TO_HOURS,
    renameKeys
} from "src/components/layouts/push/UserJobsConstants";
import Link from "@amzn/meridian/link";
import empty from "src/animations/empty.json";
import {LottieAnimation} from "src/components/common/LottieAnimation";
import {useSelector} from "react-redux";
import Icon from "@amzn/meridian/icon";
import checkCircleFilledTokens from "@amzn/meridian-tokens/base/icon/check-circle-filled"
import Text from "@amzn/meridian/text";
import {Feedback} from "src/components/layouts/Feedback";
import {v4 as uuidv4} from "uuid";
import {APP_NAME} from "src/constants/app";


export const UserJobs = (props) => {

    const {
        jobList,
        stageConfig,
        currentPage,
        setCurrentPage,
        userData,
        claimsData, themeContext
    } = props


    const [showDetails, setShowDetails] = useState(false);
    const [_openFeedBackView, _setOpenFeedbackView] = useState(false);
    const [_body, _setBody, _bodyRef] = useState("");

    //const pagination selection
    const {height, width} = useWindowDimensions();
    const [paginationValue, setPaginationValue, paginationValueRef] = useState(MAX_JOBS_PAGE_COUNT)
    const numberOfPages = Math.ceil(jobList ? (jobList.length / paginationValue) : 0)


    //sorting
    const [sortColumn, setSortColumn] = useState("Created On")
    const [sortDirection, setSortDirection] = useState("descending")
    const onSort = useCallback(({sortColumn, sortDirection}) => {
        setSortDirection(sortDirection)
        setSortColumn(sortColumn)
    }, [])
    jobList && jobList.sort((a, b) => {
        if (dateColumns.includes(sortColumn)) {
            if (parse(a[sortColumn], stageConfig.DATE.format, new Date()) < parse(b[sortColumn], stageConfig.DATE.format, new Date())) {
                return sortDirection === "ascending" ? -1 : 1
            }
            if (parse(a[sortColumn], stageConfig.DATE.format, new Date()) > parse(b[sortColumn], stageConfig.DATE.format, new Date())) {
                return sortDirection === "ascending" ? 1 : -1
            }
        } else {
            if (a[sortColumn] < b[sortColumn]) {
                return sortDirection === "ascending" ? -1 : 1
            }
            if (a[sortColumn] > b[sortColumn]) {
                return sortDirection === "ascending" ? 1 : -1
            }
        }
        return 0
    })


    //pagination
    const firstVisibleIndex = (currentPage - 1) * paginationValue
    const lastVisibleIndex = firstVisibleIndex + paginationValue
    const paginationHandler = useCallback((newPageNumber) => {
        setCurrentPage(newPageNumber)
    }, [])


    return (isEmpty(jobList) ? (<div
                style={{width: '100%', marginTop: "40px", justifyContent: "start", alignItems: "start", display: "flex",}}>
                <Row width="100%" widths={["grid-12"]} alignmentHorizontal="left">
                    <LottieAnimation animationData={empty}
                                     text={"You have not pushed any asin yet. Once you do all the jobs will appear here."}
                                     height={200}
                                     width={200}/>
                </Row></div>) :
            <div style={{borderRadius: "10px", border: "1px solid #ccc", padding: "10px"}}>
                <React.Fragment>
                    <Column className={"small"} width="100%" spacing="small" alignmentHorizontal="right">

                        {/*top action bar*/}
                        <Row width="100%" widths={["grid-12"]} alignmentHorizontal="left">
                            <TableActionBar widths={["60%", "40%"]}>
                                <Row width="100%" alignmentHorizontal="left">
                            <span>
                                SLA for job completion is <strong>{MILLISECONDS_TO_HOURS(claimsData?.waitTimes?.maxCompleteWaitTime)} hrs</strong>.
                            </span>
                                </Row>
                                <Row width="100%" alignmentHorizontal="end">
                                    {/*<Button size="small" type="tertiary"*/}
                                    {/*        onClick={() => {*/}
                                    {/*        }}>*/}
                                    {/*    Some Action</Button>*/}
                                </Row>
                            </TableActionBar>
                        </Row>

                        {/*table data*/}
                        <Row width="100%" widths={["grid-12"]} alignmentHorizontal="left">
                            <div className={'small'} style={{
                                overflowX: "auto", maxWidth: "100%", padding: "5px",
                            }}>
                                <Table
                                    width="100%"
                                    headerRows={1}
                                    spacing="small"
                                    showDividers={true}
                                    showStripes={false}
                                    sortColumn={sortColumn}
                                    sortDirection={sortDirection}
                                    onSort={onSort}
                                    fixHeaderRows={false}
                                >
                                    {/*table headers*/}
                                    <TableRow
                                        key={'header'}
                                        highlightOnHover={true}>
                                        {keys(jobList[0]).map((d) => {
                                            if (extraItems.includes(d)) return
                                            if (!hiddenItems.includes(d) || (hiddenItems.includes(d) && showDetails)) return <TableCell
                                                width="50px" key={d}
                                                sortColumn={d} alignmentHorizontal="center">
                                                {d}
                                            </TableCell>
                                        })}
                                    </TableRow>

                                    {/*table rows*/}
                                    {jobList.slice(firstVisibleIndex, lastVisibleIndex).map((row) => (
                                        <TableRow key={row[renameKeys.runId]} highlightOnHover={true}>
                                            {keys(row).map((d) => {
                                                if (extraItems.includes(d)) return
                                                if (!hiddenItems.includes(d) || (hiddenItems.includes(d) && showDetails)) {
                                                    return <TableCell key={d} alignmentHorizontal="center">
                                                        {d === renameKeys.runId ?
                                                            (<Link href={GET_RUN_ID_URL(row[d], userData?.username)}
                                                                   target="_blank"
                                                                   rel="noopener noreferrer"
                                                                   type="primary">{row[d]}</Link>) :
                                                            d === renameKeys.action ?
                                                                (
                                                                    row[renameKeys._happyCase] ?
                                                                        <Text>
                                                                            <Icon tokens={checkCircleFilledTokens}/>
                                                                        </Text> :
                                                                        row[renameKeys._breachedSla] ?
                                                                            <Button size="small" type="link"
                                                                                    onClick={() => {
                                                                                        _setBody(`The below job has breached the SLA of ${MILLISECONDS_TO_HOURS(claimsData?.waitTimes?.maxCompleteWaitTime)} hrs. Please debug and help it unblock.\n\nUser Id: ${row[renameKeys.userId]}\nRun Id: ${row[renameKeys.runId]}\nAsins: ${row[renameKeys.asins]}\nCurrent Status: ${row[renameKeys.currentStatus]}\nCradle Status: ${row[renameKeys.cradleJobStatus]}\nCreated On: ${row[renameKeys.createdAt]}\nUpdated On: ${row[renameKeys.updatedAt]}`)
                                                                                        _setOpenFeedbackView(true)
                                                                                    }}>Contact
                                                                                Team</Button> :
                                                                            <Button size="small" type="link"
                                                                                    onClick={() => {
                                                                                        _setBody(`Please enter your query here.\n\n----------------\n\nUser Id: ${row[renameKeys.userId]}\nRun Id: ${row[renameKeys.runId]}\nAsins: ${row[renameKeys.asins]}\nCurrent Status: ${row[renameKeys.currentStatus]}\nCradle Status: ${row[renameKeys.cradleJobStatus]}\nCreated On: ${row[renameKeys.createdAt]}\nUpdated On: ${row[renameKeys.updatedAt]}`)
                                                                                        _setOpenFeedbackView(true)
                                                                                    }}>Help</Button>
                                                                ) :
                                                                (
                                                                    showDetails ? row[d] :
                                                                        (
                                                                            row[d]?.length > MAX_ALLOWED_LENGTH && !MAX_LENGTH_EXCLUDED_FIELDS.includes(d) ?
                                                                                (row[d]?.slice(0, MAX_ALLOWED_LENGTH) + "...") : row[d]
                                                                        )
                                                                )
                                                        }

                                                    </TableCell>
                                                }
                                            })}
                                        </TableRow>))}

                                </Table>
                            </div>
                        </Row>

                        {/*footer*/}
                        <Row width="100%" widths={["30%", "70%"]}>
                            <Row width="100%" alignmentHorizontal="left">
                                <Toggle size="small" checked={showDetails} onChange={setShowDetails}>Detailed
                                    View</Toggle>
                            </Row>
                            <Row width="100%" alignmentHorizontal="end">
                                <Pagination
                                    showSkipArrows={true}
                                    numberOfPages={numberOfPages}
                                    onChange={setCurrentPage}
                                    currentPage={currentPage}
                                    onChange={paginationHandler}
                                />
                            </Row>
                        </Row>

                    </Column>

                    <Feedback key={uuidv4()} openFeedback={_openFeedBackView} setOpenFeedback={_setOpenFeedbackView}
                              isDarkMode={themeContext.isDarkMode} title={`[${APP_NAME}] Push Asin Job Debug Request`}
                              body={_bodyRef?.current}/>
                </React.Fragment>
            </div>


    );
};