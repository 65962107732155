import React, {useEffect, useState} from 'react';

export function useStateWithLocalStorage<StateType>(
    localStorageKey: string,
    defaultValue: StateType
): [StateType, React.Dispatch<StateType>] {
    const storedString: string | null = localStorage.getItem(localStorageKey);
    let existingValue: StateType | undefined = undefined;
    if (storedString) {
        try {
            existingValue = JSON.parse(storedString) as StateType;
        } catch (err) {
            console.error(
                `Unable to parse local storage (key = ${localStorageKey}): value = ${JSON.stringify(
                    existingValue
                )}: ${JSON.stringify(err)}`
            );
        }
    }
    const [value, setValue] = useState<StateType>(existingValue ?? defaultValue);

    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(value));
    }, [localStorageKey, value]);

    return [value, setValue];
}