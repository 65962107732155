import React from 'react';
import Lottie from "react-lottie-player";
// @ts-ignore
import {has} from "lodash";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";

export const LottieAnimation = (props: any) => {
    return (
        <div style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex", flexDirection: "column",
        }}>
            <Lottie
                animationData={props.animationData}
                loop={has(props, "loop") ? props.loop : true}
                play={has(props, "autoplay") ? props.autoplay : true}
                rendererSettings={{
                    preserveAspectRatio: "xMidYMid slice",
                }}
                style={{
                    width: props.width || (props.isMobile ? 200 : 400),
                    height: props.height || (props.isMobile ? 200 : 400),
                }}
            />
            <Text>{props.text}</Text>
        </div>
    );
}